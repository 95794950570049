import React from "react"
import { graphql } from "gatsby"
import Components from "../components/components_stories"
import Header from "../components/header"
import { Flex } from "theme-ui"
import Seo from "../components/seo"
import Footer from "../components/footer"
import { menuColor } from "./menu_color"
import { LabelsProvider } from "../utils/labels_context"

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }

    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    const globalNavi = Object.assign({}, props.pageContext.globalNavi)
    const footer = Object.assign({}, props.pageContext.footer)
    const labelTranslations = Object.assign(
      {},
      props.pageContext.labelTranslations
    )
    const related_content_1 = Object.assign({}, props.data.related_content_1)
    const related_content_2 = Object.assign({}, props.data.related_content_2)

    story.content = JSON.parse(story.content)

    related_content_1.content =
      typeof related_content_1.content === "string" ||
      related_content_1.content instanceof String
        ? JSON.parse(related_content_1.content)
        : null
    related_content_2.content =
      typeof related_content_2.content === "string" ||
      related_content_2.content instanceof String
        ? JSON.parse(related_content_2.content)
        : null

    story.content.related_content_items = {
      content_item_1: related_content_1,
      content_item_2: related_content_2,
      title: story.content.related_content_title,
    }

    globalNavi.content = JSON.parse(globalNavi.content)
    footer.content = JSON.parse(footer.content)
    labelTranslations.content = JSON.parse(labelTranslations.content)

    return { story, globalNavi, footer, labelTranslations }
  }

  constructor(props) {
    super(props)

    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    let content = this.state.story.content
    let globalNavi = this.state.globalNavi.content
    let footer = this.state.footer.content
    let langSwitch = this.state.story.translated_slugs
    content.sort_by_date = this.state.story.sort_by_date
    content.seo.alternateUrls = langSwitch
    content.lang =
      content.seo.lang =
      globalNavi.lang =
      langSwitch.currentLang =
        this.state.story.lang

    return (
      <Flex sx={{ minHeight: "100vh", flexDirection: "column" }}>
        <LabelsProvider
          value={{
            lang: this.state.story.lang,
            labels: this.state.labelTranslations.content,
          }}
        >
          <Header
            blok={globalNavi}
            langs={langSwitch}
            menuColor={menuColor(content)}
          ></Header>
          {React.createElement(Components(content.component), {
            key: content._uid,
            blok: content,
          })}
          <Footer blok={footer} langs={langSwitch} />
        </LabelsProvider>
      </Flex>
    )
  }
}

export default StoryblokEntry

export const Head = props => (
  <Seo meta={props.pageContext.story} lang={props.pageContext.lang} />
)

export const pageQuery = graphql`
  query (
    $lang: String!
    $related_content_1: String
    $related_content_2: String
  ) {
    related_content_1: storyblokEntry(
      uuid: { eq: $related_content_1 }
      lang: { eq: $lang }
    ) {
      id
      uuid
      name
      lang
      content
      full_slug
    }
    related_content_2: storyblokEntry(
      uuid: { eq: $related_content_2 }
      lang: { eq: $lang }
    ) {
      id
      uuid
      name
      lang
      content
      full_slug
    }
  }
`
